@import 'styles/_fontfaces.scss';
@import 'styles/_palettes.scss';
@import 'styles/_material.scss';
@import 'styles/_theme.scss';

html, body {
  height: 100%;
  color: $eon-darkgrey;
  background-color: $eon-white;
  overscroll-behavior-y: contain;
}

body {
  margin: 0;
  font-family: $font-regular;
  font-size: 18px;
}

.full-height {
  height: 100%;
}

.dialog-container-custom .mat-dialog-container {
  border-radius: 0;
  padding: 48px;
  display: flex;
  flex-direction: column;
}

.notification-success {
  background-color: $eon-turquoise-75;
  color: $eon-white;

  .mat-simple-snackbar-action button {
    color: $eon-white;
    background-color: $eon-dark-turquoise;
    cursor: pointer;
  }
}


.notification-failure {
  background-color: $color-eonred;
  color: $eon-white;

  .mat-simple-snackbar-action button {
    color: $eon-white;
    background-color: $eon-bordeaux;
    cursor: pointer;
  }
}


mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix  { padding: 0.4em 0px }
mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix > span.mat-form-field-label-wrapper { top: -1.35em; }

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.1em) scale(.75);
  width: 133.33333%;
}

.eon-style-form-field.no-padding .mat-form-field-wrapper {padding: 0}
.eon-style-form-field.no-padding div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix  { padding-bottom: 0.84375em !important; }

.mat-tooltip {
  max-width: unset !important;
}

div.mat-menu-content:not(:empty) {
  padding: 0;
}

div.mat-menu-panel {
  border-radius: 0;
  color: $eon-darkgrey;
}

.mat-menu-item{
  color: $eon-darkgrey;
}

.table-container {
  max-height: calc(100vh - 150px);
  overflow: auto;
}

.mat-column-online {
  text-align: center !important;
  width: 124px;
  padding-left: 0 !important;
  .mat-sort-header-container {
    justify-content: center !important;
  }
}

.mat-drawer-transition .mat-drawer-backdrop {
  position: fixed;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.margin-right-24 {
  margin-right: 24px;
}

.main-tab-group {
  .mat-tab-labels {
    justify-content: center;
  }

  .mat-tab-label-active {
    background: $eon-lightgrey;

    label {
      font-family: "eonBrixBold", Helvetica, sans-serif;
    }
  }

  .mat-tab-header {
    .mat-tab-label {
      flex-grow: inherit !important;
      min-width: 300px;
      opacity: 1;

      &:first-child {
        min-width: 400px;
      }
    }
  }

  .mat-tab-body {
    padding: 24px 120px;
    background: $eon-super-lightgrey;
  }

  .tab-group {
    .mat-tab-labels {
      justify-content: start;
    }

    .mat-tab-label-active {
      background: white;
      font-family: "eonBrixBold", Helvetica, sans-serif;
    }

    .mat-tab-body {
      padding: 0px;
    }
  }
}

.tab-label-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  label {
    font-size: 18px;
    font-weight: 700;
    color: $eon-darkgrey;
    margin-right: 12px;
  }

  .mobile-device-counter {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    margin-right: 8px;
    background-color: $eon-darkgrey;
    border-radius: 4px;
    color: white;
    position: relative;

    &.with-tag {
      padding-left: 2px;

      mat-icon {
        margin-left: 8px;
      }
    }

    img {
      margin-right: 8px;
    }

    mat-icon {
      margin-right: 8px;
      height: 16px;
      line-height: 16px;
    }

    label.tag {
      color: black;
      font-size: 9px;
      padding: 2px;
      background: white;
      border-radius: 2px;
      margin: 0 4px;
    }
  }
}

.mat-tab-header {
  border-bottom: 1px solid #D7D0CC;
}

.mat-tab-label-content {
  font-size: 18px;
  font-weight: 700;
  color: $eon-darkgrey;
  margin-right: 12px;
}
