@use '@angular/material' as mat;
@import "palettes";

$color-white: mat.get-color-from-palette($md-grey, 50) !default;
$color-black: mat.get-color-from-palette($md-grey, 0) !default;

$color-grey-100: mat.get-color-from-palette($md-grey, 100) !default;
$color-grey-200: mat.get-color-from-palette($md-grey, 200) !default;
$color-grey-500: mat.get-color-from-palette($md-grey, 500) !default;
$color-grey-600: mat.get-color-from-palette($md-grey, 600) !default;
$color-grey-800: mat.get-color-from-palette($md-grey, 800) !default;
$color-grey-900: mat.get-color-from-palette($md-grey, 900) !default;
$color-grey-A100: mat.get-color-from-palette($md-grey, A100) !default;
$color-grey-A200: mat.get-color-from-palette($md-grey, A200) !default;

$color-eonred: mat.get-color-from-palette($md-eonred, 500) !default;
$color-eonbordeaux: mat.get-color-from-palette($md-eonbordeaux, 500) !default;
$color-limeyellow: mat.get-color-from-palette($md-limeyellow, 500) !default;
$color-turquoise: mat.get-color-from-palette($md-turquoise, 500) !default;
$color-turquoise-dark: mat.get-color-from-palette($md-turquoise, 900) !default;

$eon-darkgrey: #39393A;
$eon-darkgrey-75: #404040;
$eon-middlegrey: #8F9192;
$eon-lightgrey: #E8E8E8;
$eon-white: #FFFFFF;
$eon-super-lightgrey: #F6F6F7;

$eon-black: #000000;
$eon-black-100: $eon-black;
$eon-black-20: #E6E6E6;
$eon-black-30: #CCCCCC;
$eon-black-40: #B3B3B3;
$eon-black-50: #999999;

$eon-dark-turquoise: #00738b;
$eon-turquoise: #1EA2B1;
$eon-turquoise-75: #2B91A3;
$eon-turquoise-25: #C7E8EB;
$eon-green: #2BA35B;

$eon-bordeaux: #b00402;
$eon-bordeaux-25: #ebc0c0;


$color-text: $eon-darkgrey !default;
$color-text-light: $eon-middlegrey !default;
$color-text-medium: $eon-darkgrey !default;
$color-text-dark: $color-grey-A200 !default;

$color-background: $color-grey-100 !default;
$color-background-light: mat.get-color-from-palette($md-grey, 50) !default;
$color-background-dark: $color-grey-200 !default;

$font-black: "eonBrixBlack", Helvetica, sans-serif !default;
$font-bold: "eonBrixBold", Helvetica, sans-serif !default;
$font-regular: "eonBrixSans", Helvetica, sans-serif !default;
$font-medium: "eonBrixMedium", Helvetica, sans-serif !default;
