/*
Fonts
 */
@font-face {
  font-family: 'eonBrixSans';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Eon-Brix/EON_BrixSansWeb-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'eonBrixMedium';
  font-style: normal;
  font-weight: 100;
  src: url('../assets/fonts/Eon-Brix/EON_BrixSansWeb-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'eonBrixBold';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/Eon-Brix/EON_BrixSansWeb-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'eonBrixBlack';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/Eon-Brix/EON_BrixSansWeb-Black.ttf') format('truetype');
  font-display: swap;
}
