// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import 'src/styles/theme';
@import 'src/styles/fontfaces';
@import 'src/styles/palettes';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$primary: mat.define-palette($md-eonred);
$accent: mat.define-palette($md-turquoise);

// The warn palette is optional (defaults to red).
$warn: mat.define-palette($md-eonbordeaux);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme: mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn,
  )
));

$typography: mat.define-typography-config(
  $font-family: 'eonBrixSans, sans-serif',
  $headline: mat.define-typography-level(10px, 10px, 700),
  $body-1: mat.define-typography-level(16px, 24px, 500)
);

@include mat.all-component-typographies($typography);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);

/* You can add global styles to this file, and also import other style files */

// material-overrides

.mat-stepper-hide-header {
  .mat-horizontal-stepper-header-container {
    display: none;
  }

  .mat-horizontal-content-container {
    padding: .5rem;
  }

  &.full-width {
    .mat-horizontal-content-container {
      overflow: visible;
    }
  }
}

.mat-toolbar {
  background: $color-white;
  height: 60px;
}

.mat-tooltip {
  background: $eon-darkgrey;
  font-size: initial;
  position: relative;
  top: -20px;
  max-width:350px;
}

.mtx-tooltip {
  background: $eon-darkgrey !important;
  font-size: initial !important;
  max-width: 350px !important;
  position: relative;
  top: -20px;
  padding: 24px !important;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  padding: 0 !important;
}

.mat-button-toggle-group-appearance-standard {

  @media only screen and (min-width: 401px) and (max-width:1024px) {
    width: 270px;
  }

  border: solid 1px $eon-lightgrey;
  border-radius: 8px !important;

  &.invalid {
    border-color: $color-eonbordeaux;
  }

  .mat-button-toggle-label-content {
    display:flex !important;
    align-items: center;
    justify-content: center;
    font-family: $font-bold;
    font-size: 18px;
    height: 26px;
    line-height: 26px;
    padding: 18px 25px !important;
  }

  .mat-button-toggle-focus-overlay {
    opacity: 0 !important;
  }

  .mat-button-toggle {
    background-color: $eon-super-lightgrey;
    color: $eon-darkgrey;

    :hover {
      background-color: $eon-lightgrey;
    }
  }

  .mat-button-toggle-checked {
    background-color: $color-turquoise;
    color: $eon-white;

    .mat-button-toggle-label-content {
      font-family: $font-bold;
    }

    :hover {
      background-color: $color-turquoise;
      color: $eon-white;
    }

    .mat-button-toggle-focus-overlay {
      border-bottom: 0;
    }
  }

  &.mat-button-toggle-vertical {
    @media only screen and (max-width: 400px) {
      flex-grow: 1;
    }
  }
}

.year-only-datepicker {
  .mat-calendar-period-button {
    display: none;
  }
}

.mat-form-field-appearance-outline {
  .mat-form-field-outline-thick {
    color: $eon-middlegrey;
    opacity: 1 !important;
  }

  .mat-form-field-infix {
    width: unset;
  }

  .mat-form-field-label {
    color: $eon-darkgrey;
  }

  &.mat-focused {
    .mat-form-field-label {
      &.mat-primary, &.mat-accent, &.mat-warn {
        color: $eon-darkgrey;
      }
    }
  }

  &.mat-warn.is-invalid {
    .mat-form-field-outline, .mat-form-field-outline-thick {
      color: mat.get-color-from-palette($warn);
    }
  }
}
